import type { FormInstance } from "antd";

import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import type { AppTheme } from "smavesto.core/lib/static/themes/AppTheme";
import { openIndependentInfoModal } from "@theme/modal/openThemedModal";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";

export const validateInvestmentSettings =
  (form: FormInstance, theme: AppTheme, onboardingLabels: any) => async () => {
    const onlySustainableCompanies = form.getFieldValue(
      "onlySustainableCompanies"
    );

    const noForeignCurrency = !form.getFieldValue("foreignCurrency");
    const noEmergingMarkets = !form.getFieldValue("emergingMarkets");
    const queryParams = `onlySustainableCompanies=${onlySustainableCompanies}&noForeignCurrency=${noForeignCurrency}&noEmergingMarkets=${noEmergingMarkets}`;

    const validationResult = await smavestoCore.services.validation.validate(
      "validate-investment-settings",
      queryParams
    );

    if (isApiErrorResponse(validationResult)) {
      openIndependentInfoModal(
        onboardingLabels.investmentRisk.modalOptions.investmentSettings,
        theme
      );
      form.setFieldsValue({ emergingMarkets: false });
      form.setFieldsValue({ foreignCurrency: false });
    }
  };
