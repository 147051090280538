import type { FormInstance, RadioChangeEvent } from "antd";
import { Form } from "antd";
import { useRecoilState } from "recoil";

import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { isValidRiskToStrategy } from "@page-components/onboarding/FinancialPosition/strategy/bus";
import {
  parseRiskNumValueToLabel,
  parseStrategyNumValueToLabel
} from "@page-components/onboarding/FinancialPosition/strategy/helpers";
import { useIsUnderageMode } from "@page-components/onboarding/hooks";
import { userUriState } from "@states/auth.state";
import {
  financialPositionState,
  useRefreshFinancialPosition
} from "@states/financial-position.state";
import {
  Actions,
  strategyRiskErrorsState,
  strategyRiskReducer,
  strategyRiskState
} from "@states/strategy-risk.state";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";

import {
  updateAndValidateStrategy,
  updateRiskAndStrategyErrorsState
} from "./investmentRiskValidators";
import { useOnFinishRiskModalForm } from "./useOnFinishRiskModalForm";

export const useRiskModalForm = (
  parentForm: FormInstance,
  destroyModal: () => void
) => {
  const [form] = Form.useForm();

  const isUnderageMode = useIsUnderageMode();

  const appMode = isUnderageMode ? "activeOnboardingOwner" : "loggedInUser";

  const [, userUri] = useAsyncSelector(userUriState(appMode));

  const [, financialPosition] = useAsyncSelector(
    financialPositionState(appMode)
  );

  const refreshFinancialPosition = useRefreshFinancialPosition(appMode);

  const [{ risk, strategy }, setStrategyRiskState] =
    useRecoilState(strategyRiskState);

  const [errorMessages, setErrors] = useRecoilState(strategyRiskErrorsState);

  const onRiskSliderAfterChange = async (value: number) => {
    setStrategyRiskState(strategyRiskReducer(Actions.changeRisk(value)));

    if (!strategy) return;

    const isValid = isValidRiskToStrategy(strategy, value);

    const riskLabel = parseRiskNumValueToLabel(value);
    const strategyLabel = parseStrategyNumValueToLabel(strategy || "HIGH_RISK");

    updateRiskAndStrategyErrorsState(
      isValid,
      setErrors,
      `Das Anlagerisiko '${riskLabel}' ist nicht mit dem Anlageziel '${strategyLabel}' kompatibel.`,
      `Das Anlageziel '${strategyLabel}' ist nicht mit dem Anlagerisiko '${riskLabel}' kompatibel.`
    );

    if (!isValid) return;

    const validationResult = await smavestoCore.services.validation.validate(
      "validate-risk",
      `risk=${value}`,
      userUri
    );
    if (errorMessages.apiError) setErrors(prev => ({ ...prev, apiError: `` }));

    if (!userUri && value < 7) {
      setErrors(prev => ({
        ...prev,
        apiError: `Das Anlagerisiko '${riskLabel}' ist nicht mit dem Anlageziel '${strategyLabel}' kompatibel. Fehler: Das gewählte Anlagerisiko ist zu gering.`
      }));
    } else if (userUri && isApiErrorResponse(validationResult)) {
      setErrors(prev => ({
        ...prev,
        apiError: `Das Anlagerisiko '${riskLabel}' ist nicht mit dem Anlageziel '${strategyLabel}' kompatibel. Fehler: ${validationResult.humanReadableError}`
      }));
    }
  };

  const onStrategyChange = async ({ target: { value } }: RadioChangeEvent) => {
    if (!userUri) return;
    setStrategyRiskState(strategyRiskReducer(Actions.changeStrategy(value)));

    const isValid = isValidRiskToStrategy(value, risk ?? 35);

    const riskLabel = parseRiskNumValueToLabel(risk ?? 35);
    const strategyLabel = parseStrategyNumValueToLabel(value ?? "HIGH_RISK");

    updateRiskAndStrategyErrorsState(
      isValid,
      setErrors,
      `Das Anlagerisiko '${riskLabel}' ist nicht mit dem Anlageziel '${strategyLabel}' kompatibel.`,
      `Das Anlageziel '${strategyLabel}' ist nicht mit dem Anlagerisiko ${riskLabel} kompatibel.`
    );

    const validationResult = await updateAndValidateStrategy(
      financialPosition,
      value,
      userUri
    );

    if (errorMessages.apiError) setErrors(prev => ({ ...prev, apiError: `` }));

    if (isApiErrorResponse(validationResult)) {
      refreshFinancialPosition();
      setErrors(prev => ({
        ...prev,
        apiError: `Das Anlageziel '${strategyLabel}' ist nicht mit dem Anlagerisiko ${riskLabel} kompatibel. Fehler: ${validationResult.humanReadableError}`
      }));
    }
  };

  return {
    form,
    risk,
    strategy,
    errorMessages,
    onRiskSliderAfterChange,
    onStrategyChange,
    ...useOnFinishRiskModalForm(parentForm, destroyModal)
  };
};
