import type { FormInstance } from "antd";

import { FormSectionWrapper } from "@components/form/inputs/form-fields/FormSectionWrapper";
import InfoCollapseBox from "@components/layout/dashboard/InfoCollapseBox";
import useOnboardingLabels from "@hooks/onboarding/labels/useOnboardingLabels";
import { SFormItem } from "@styled-components/form/SFormItems";

import { RiskSlider } from "../../FinancialPosition/strategy/RiskSlider";
import { useInvestmentRisk } from "./useInvestmentRisk";

interface InvestmentRiskFormSectionProps {
  form: FormInstance;
  isUnderage?: boolean;
  hideTitle?: boolean;
  source?: "dashboard" | "onboarding";
}

const InvestmentRiskFormSection: React.FunctionComponent<
  InvestmentRiskFormSectionProps
> = ({ form, isUnderage, hideTitle, source }) => {
  const { risk, onSliderAfterChange, contextHolder } = useInvestmentRisk(form);
  const labels = useOnboardingLabels(source);

  return (
    <FormSectionWrapper tag="none">
      <SFormItem
        name="investmentRisk"
        initialValue={risk}
        label={!hideTitle && "Anlagerisiko"}
      >
        <RiskSlider
          isUnderage={isUnderage || false}
          marks={labels.investmentSettings.investmentRiskMarks}
          min={0}
          max={40}
          step={1}
          margin="16px 0 28px 0"
          labelMargin="0 0 40px 0"
          tipFormatter={(value?: number) => `${value ?? 0}% max. Drawdown`}
          onAfterChange={onSliderAfterChange}
        />
      </SFormItem>
      <InfoCollapseBox
        style={{ margin: "38px 0" }}
        title={
          isUnderage
            ? "Sie wissen nicht, ob das gewählte Anlagerisiko für Ihr Kind das Passende ist?"
            : "Sie wissen nicht ob das gewählte Anlagerisiko das Passende ist?"
        }
      >
        {labels.investmentRisk.infoAboutChanges}
      </InfoCollapseBox>
      {contextHolder}
    </FormSectionWrapper>
  );
};

export default InvestmentRiskFormSection;
